<template>
  <div class="inner-layout">

    <div class="rolling-banner" v-if="bannerList7010.length > 0">
      <VueSlickCarousel v-bind="this.bannerSettings">
        <div v-for="(item, index) in bannerList7010" :key="`banner-7010-${index}`">
          <a :href="item.link" :target="item.linkTarget"><img :src="item.img"></a>
        </div>
      </VueSlickCarousel>
    </div>


    <!-- 반려동물 -->
    <!-- 반려동물이 없거나(=비회원)/반려동물이 있고 관심키워드가 없을 때 + 진열 데이터가 없을 때 -> 미노출 -->
    <template v-if="((mainPetId === 0) || (mainPetId > 0 && petKeyword.length === 0)) && recommend.list.length === 0"></template>
    <!-- 반려동물이 없거나(=비회원)/반려동물이 있고 관심키워드가 없을 때 + 진열 데이터가 있을 때 -> 노출 -->
    <template v-else-if="((mainPetId === 0) || (mainPetId > 0 && petKeyword.length === 0) ) && recommend.list.length > 0 ">
      <div class="main-heading-group recommend">
        <div class="heading-3">우리 아이를 위한 추천 정보</div>
        <div v-if="mainPetId === 0" class="recommend-btn" @click="mypetRegister">🐶 <span class="color-1">반려동물 등록</span>하고 추천정보 받기 <span class="icon-arrow"></span></div>
        <div v-else class="recommend-btn" @click="mypetModify">🐶 <span class="color-1">관심키워드 등록</span>하고 추천정보 받기 <span class="icon-arrow"></span></div>
        <div class="right-link"><router-link to="/lab">더보기 <span class="icon-arrow"></span></router-link></div>
      </div>
      <div class="item-container" :class="recommend.list.length > 4 ? 'roll' : 'column-4'">
        <VueSlickCarousel v-bind="labSettings">
          <main-card v-for="(item,index) in recommend.list" :item="item" :index="index" :key="`recommend-${item.boIdx}-${index}`" @click.native="$router.push(`/lab/${item.boIdx}`)"/>
        </VueSlickCarousel>
      </div>
    </template>
    <!-- 반려동물이 있고 관심키워드가 있을 때 -->
    <template v-else-if="mainPetId > 0 && petKeyword.length > 0"><!--&& recommend.category.name-->
      <div class="main-heading-group">
        <div class="heading-3">{{ recommend.name }}<div class="desc">{{ recommend.subName }}</div></div>
        <div class="btn-list main-keyword-list">
          <label v-for="(item, index) in recommend.category.name" :key="`recommend-name-${index}`" class="round-btn">
            <input type="radio" name="recommend" :value="index" v-model="selectRecommendIndex" >
            <span>{{ item === 'all' ? '전체' : item }}</span>
          </label>
        </div>
        <div class="right-link"><router-link to="/lab">더보기 <span class="icon-arrow"></span></router-link></div>
      </div>
      <template v-if="recommend.category.list[selectRecommendIndex].length === 0">
        <div class="noDataMessage2">{{ $msg('nodata3') }}</div>
      </template>
      <template v-else-if="recommend.category.list[selectRecommendIndex].length < 4">
        <div class="item-container column-4">
          <main-card v-for="(item,index) in recommend.category.list[selectRecommendIndex]" :key="`recommend-${item.boIdx}-${index}`"
                     :item="item" :index="index" @click.native="$router.push(`/lab/${item.boIdx}`)" />
        </div>
      </template>
      <template v-else>
        <div class="item-container roll">
          <VueSlickCarousel v-bind="labSettings">
            <main-card v-for="(item,index) in recommend.category.list[selectRecommendIndex]" :key="`recommend-${item.boIdx}-${index}`"
                       :item="item" :index="index" @click.native="$router.push(`/lab/${item.boIdx}`)" />
          </VueSlickCarousel>
        </div>
      </template>
    </template>


    <!-- md추천 -->
    <template v-if="md.list.length > 0">
    <div class="main-heading-group">
      <div class="heading-3">
        {{ md.name }}
        <div class="desc">{{ md.subName }}</div>
      </div>
    </div>
    <div class="item-container column-4">
      <main-card v-for="(item,index) in md.list" :item="item" :index="index" :key="`md-${item.boIdx}-${index}`" @click.native="goDetail(item.parentCategory, item.boIdx)" />
    </div>
    </template>

    <!-- 중간배너 -->
    <div class="rolling-banner" v-if="bannerList7012.length > 0">
      <VueSlickCarousel v-bind="this.bannerSettings">
        <div v-for="(item, index) in bannerList7012" :key="`banner-7012-${index}`">
          <a :href="item.link" :target="item.linkTarget"><img :src="item.img"></a>
        </div>
      </VueSlickCarousel>
    </div>

    <!-- 꼬리tip -->
    <div class="main-heading-group">
      <div class="heading-3">
        {{ tip.name }}
        <div class="desc">{{ tip.subName  }}</div>
      </div>
      <div class="btn-list line1">
        <label v-for="(item, index) in tip.category.name" :key="`tip-name-${index}`" class="round-btn">
          <input type="radio" name="tip" :value="index" v-model="selectTipIndex" >
          <span>{{ item === 'all' ? '전체' : item }}</span>
        </label>
      </div>
      <div class="right-link">
        <router-link to="/tip">더보기 <span class="icon-arrow"></span></router-link>
      </div>
    </div>
    <div class="item-container column-4">
      <template v-for="(item,index) in tip.category.list[selectTipIndex]" >
        <main-card v-if="index < 4" :item="item" :index="index" :key="`tip-${item.boIdx}-${index}`" @click.native="goDetail(item.parentCategory, item.boIdx)" />      
      </template>
      <div v-if="!tip.category.list[selectTipIndex]" class="noDataMessage2">{{ $msg('nodata4') }}</div>
    </div>

    <!-- 상품 -->
    <div v-if="productCtgryList && productCtgryList.length > 0">
      <div class="main-heading-group">
        <div class="heading-3">
          {{ productMainCtgry.cateName }}
          <div class="desc">{{ productMainCtgry.cateSubName }}</div>
        </div>
      </div>
      <div class="main-cateList">
        <div v-for="item in productCtgryList" :key="`product-ctgry-${item.cateCode}`"
             :class="{ on: item.cateCode === productCtgry.cateCode }" @click="setProductCtgry(item)">{{ item.cateName }}</div>
      </div>
      <div class="item-container">
        <div class="default-item main-item" v-for="item in productList" :key="item.cateName">
          <a :href="item.url" target="_blank">
            <div class="item-img">
              <div class="thumb-img"><div><img :src="item.img" loading="”lazy”/" style=""></div></div>
            </div>
            <div class="recommend-con">
              <div class="item-title">{{ item.productName }}</div>
              <div class="item-info">
                <span class="price">{{ Number(item.price).numberFormat() }}원</span>
              </div>
            </div>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
import carouselMixin from "@/mixins/carouselMixin"
import bannerMixin from "@/mixins/bannerMixin"
import listMixin from "@/mixins/listMixin"
import MainCard from "@/components/common/MainCard"
export default {
  components: { MainCard },
  mixins: [carouselMixin, bannerMixin, listMixin],
  data() {
    return {
      bannerList7010: [],
      bannerList7012: [],
      mainPetId: 0,
      petKeyword: [],
      recommend: {
        name: null,
        subName: null,
        category: {
          name: [],
          list: [],
        },
        list: [],
      },
      selectRecommendIndex: 0,
      md: {
        name: null,
        subName: null,
        list: [],
      },
      selectTipIndex:0,
      tip: {
        name: null,
        subName: null,
        category: {
          name: [],
          list: [],
        },
      },
      productMainCtgry: {
        cateCode: null,
        cateName: null,
        cateSubName: null,
      },
      productCtgryList: null,
      productCtgry: null,
      productList: [],
      isShowProductCount: 6,
      popup: null,
      idx: null,
    }
  },
  computed: {
    ...mapGetters({
      isLogin: 'session/isLogin',
      getMainPetId: 'common/getMainPetId',
    }),
  },
  watch:{
    getMainPetId() {
      this.resetList()
      this.getList()
    },
  },
  created() {
    this.getList()
    this.setBanner()
    this.setProductMainCtgry()
  },
  methods: {
    ...mapActions({
      setMsgPopup: 'common/setMsgPopup',
    }),
    async getList() {
      const { result, data } = await this.$api.main();
      if (result === 'success') {
        this.mainPetId = data.mainPet
        this.petKeyword = data.keyword
        this.recommend = {
          name: data.recommend.name,
          subName: data.recommend.subName,
          category: {
            name: data.recommend.category ? data.recommend.category.map(g => g.categoryName) : [],
            list: data.recommend.category ? data.recommend.category.map(g => g.list) : [],
          },
          list: data.recommend.list ? data.recommend.list : [], //메인펫의 관심키워드가 없을때 노출되는 리스트
        }
        this.md = data.md
        this.tip = {
          name: data.tip.name,
          subName: data.tip.subName,
          category: {
            name: data.tip.category.map(g => g.categoryName),
            list: data.tip.category.map(g => g.list),
          }
        }
      }
    },
    resetList() {
      this.mainPetId = 0
      this.petKeyword = []
      this.recommend = {
        name: null,
        subName: null,
        category: {
        name: [],
          list: [],
        },
        list: [],
      }
      this.md = {
        name: null,
        subName: null,
        list: [],
      }
      this.tip = {
        name: null,
        subName: null,
        category: {
        name: [],
          list: [],
        }
      }
    },
    async setBanner() {
      this.bannerList7010 = await this.getBanner(7010)
      this.bannerList7012 = await this.getBanner(7012)
    },
    async getProductCtgry(params) {
      const { result, data } = await this.$api.getMainProductCtgry(params)
      return result === 'success' ? data : null
    },
    async setProductMainCtgry() {
      const ctgryList = await this.getProductCtgry(null)
      this.productMainCtgry = ctgryList && ctgryList[0]
      await this.setProductCtgryList(ctgryList[0].cateCode)
    },
    async setProductCtgryList(cateCode) {
      this.productCtgryList = await this.getProductCtgry({ target: cateCode })
      if (this.productCtgryList) {
        this.productCtgry = this.productCtgryList[0]
        await this.getProductList()
      }
    },
    setProductCtgry(ctgry) {
      this.productCtgry = ctgry
      this.getProductList()
    },
    async getProductList() {
      const { result, list } = await this.$api.getMainProductList({ cateCode: this.productCtgry.cateCode });
      if (result === 'success') this.productList = list
    },
    mypetRegister() {
      this.checkLoginStatus('/mypet/register')
    },
    mypetModify() {
      this.$router.push(`/mypet/modify/${this.mainPetId}`)
    },
    checkLoginStatus(path) {
      if (!this.isLogin) {
        this.loginPopup(path)
      }
      else {
        this.$router.push(path)
      }
    },
    loginPopup(path) {
      this.setMsgPopup({
        type: 'alert',
        message: this.$msg('login.need'),
        okay: 'OK',
        okayCallback: () => {
          this.setMsgPopup()
          localStorage.setItem('loginBeforePage', path)
          this.$router.push('/login')
        }
      })
    },
    goDetail(cateCode, boIdx) {
      this.setBeforeContent()
      if (cateCode === 10) this.$router.push(`/tip/${boIdx}`)
      if (cateCode === 20) this.$router.push(`/lab/${boIdx}`)
    },
  }
}
</script>
