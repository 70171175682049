<template>
  <div class="default-item cursor-pointer">
    <div class="item-img">
      <icon-label :item="item"/>
      <div class="thumb-img"><image-alt :src="item.thumbnail" altType="list"></image-alt></div>
    </div>
    <div class="item-con">
      <div class="ellipsis-title">{{ item.title }}</div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['item', 'index'],
  data() {
    return {
    }
  },
  methods: {
  }
}
</script>
